import React from 'react';
import { Dropdown, Layout, Menu } from 'antd';
import { SettingOutlined, LogoutOutlined, DownOutlined } from '@ant-design/icons';
import propTypes from 'prop-types';
import './authorizedlayout.less';
import { NavLink, withRouter } from 'react-router-dom';
import { PATHS }  from '../../constants';
import { AUTH_CHECK } from '../../queries';
import { useQuery } from '@apollo/client';
import history from '../../Utilities/history';
import { authenticationService } from '../../Utilities/authenticationService';

const { Header, Content } = Layout;

const AuthorizedLayout = ({ location, children, disableBorder, onLogout }) => {
    useQuery(AUTH_CHECK, {
        onError: () => {
            authenticationService.logout();
            history.push({
                pathname: PATHS.login,
                state: { from: { location: location.pathname } }
            });
        }
    });

    const UserMenu = <Menu key="user-panel" style={{ background: '#fff' }}>
        <Menu.Item icon={<SettingOutlined />} key="settings"><NavLink to={PATHS.userSettings}>アカウント</NavLink></Menu.Item>
        <Menu.Item icon={<LogoutOutlined />} key='logout' onClick={onLogout}>ログアウト</Menu.Item>
    </Menu>;

    const Topbar = <Header className="main-header" style={{ position: 'fixed', zIndex: 1000, width: '100%' }}>
        <Menu theme="dark" mode="horizontal" selectedKeys={[location.pathname.split('/')[1]]}>
            <Menu.Item className='home-icon'>
                <img src={require('../../static/logo.jpg')} alt='horipro' className='app-logo' /></Menu.Item>
            <Menu.Item key={'talents'}><NavLink to={PATHS.talents}>タレント一覧</NavLink></Menu.Item>
            <Menu.Item key={'chatGPT'}><NavLink to={PATHS.chatGPT}>AIに質問</NavLink></Menu.Item>
            <Menu.Item key={'keywordSearch'}>
                <NavLink to={PATHS.keywordSearch.replace('/:dataSource?/:name?/:date?/:keyword?', '')}>
                    ラベルツール
                </NavLink>
            </Menu.Item>
            <Menu.Item key={'dashboard'}><NavLink to={PATHS.dashboard.replace(':nameId?', '')}>ダッシュボード</NavLink></Menu.Item>
            <Menu.Item key={'fileArea'}><NavLink to={PATHS.fileArea}>分析テンプレート</NavLink></Menu.Item>
            <Menu.Item key={'talentCloud'}><NavLink to={PATHS.talentCloud}>タレントクラウド</NavLink></Menu.Item>
            <Menu.Item className="user-panel" style={{ marginLeft: 'auto' }}>
                <Dropdown overlay={UserMenu} trigger={['click']}>
                    <div>{ JSON.parse(localStorage.currentUser).email } <DownOutlined /></div>
                </Dropdown>
            </Menu.Item>
        </Menu>
    </Header>;

    return <Layout style={{ minHeight: '100vh' }}>
        { Topbar }
        <Content
            className={ disableBorder ? '' : 'site-layout-background' }
            style={{
                padding: '24px 24px 24px 24px',
                margin: 0,
                marginTop: 64,
                minHeight: 280,
                borderTop: '1px solid #f0f0f0'
            }}
        >
            { children }
        </Content>
    </Layout>;
};

AuthorizedLayout.propTypes = {
    children: propTypes.element,
    onLogout: propTypes.func,
    location: propTypes.object,
    disableBorder: propTypes.bool
};

export default withRouter(AuthorizedLayout);
