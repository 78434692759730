import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { GRAVITY_API_ROOT } from '../../constants';
import { authenticationService } from '../../Utilities/authenticationService';
import { Input, Button, Divider } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import chatgptIcon from '../../static/chatgpt-icon.png';

const AnalyzeComments = ({talentId}) => {
    const [question, setQuestion] = useState('');
    const [loading, setLoading] = useState(false);
    const [conversationHistory, setConversationHistory] = useState([]);

    const handleSearch = () => {
        setLoading(true);

        const messages = conversationHistory.length === 0
        ? [
            { content: `${question} talent_id: ${talentId}`, type: 'human' }
          ] : [
            ...conversationHistory,
            { content: `${question} talent_id: ${talentId}`, type: 'human',  }
          ];

        console.log(JSON.stringify({
            input: {
                messages: messages,
                next: "supervisor",
            },
            config: {}
        }));

        fetch(`${GRAVITY_API_ROOT}/chatgpt/chat_analysis`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${authenticationService.currentUserValue.token}`,
                'Content-Type' : 'application/json',
            },
            body : JSON.stringify({
                input: {
                    messages: messages,
                    next: "supervisor",
                },
                config: {}
            }),
        })
        .then(response => {
            return response.json(); // Parse JSON from response body
        })
        .then(data => {
            const answer = data?.response ?? 'No answer available'; // Extract the content from response
            setLoading(false);
            setConversationHistory([...messages, { type: 'human', content: answer }]); // Add the answer to history
            setQuestion(''); // Clear the question input field
        })
        .catch(error => {
            console.error("Error during fetch:", error); // Log any fetch errors
            setLoading(false);
        });
    }

    const renderContent = (message, index) => {
    
        return (
            <div style={{ display: 'flex', alignItems: 'flex-start', marginBottom: '16px' }}>
                {/* Conditional rendering for icons */}
                {index % 2 === 0 ? (
                    <div
                        style={{
                            width: '80px',
                            height: '80px',
                            marginRight: '12px',
                        }}
                    />
                ) : (
                    <img
                        src={chatgptIcon}
                        alt="ChatGPT Icon"
                        style={{
                            width: '80px',
                            height: '80px',
                            marginRight: '12px',
                            borderRadius: '50%',
                        }}
                    />
                )}
                <div style={{ maxWidth: '600px', wordBreak: 'break-word' }}>
                    {/* Use react-markdown to render content */}
                    <ReactMarkdown>{message.content}</ReactMarkdown>
                </div>
            </div>
        );
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', marginBottom: '16px' }}>
            <div style={{ display: 'flex', marginBottom: '16px' }}>
                <Input
                    placeholder="質問を入力してください (例: 〇〇の演技についての評価を教えてください)"
                    style={{ width: '80%', marginRight: '1%'}}
                    value={ question }
                    onChange={(e) => setQuestion(e.target.value)}
                />
                <Button 
                    type="primary" 
                    onClick={handleSearch} 
                    style={{ width: '10%', marginRight: '1%' }} 
                    loading={loading} 
                    disabled={question === ''}
                >
                    質問する
                </Button>
                <Button type="primary"
                        onClick={() => setConversationHistory([])}
                        style={{ width: '8%'}}
                        icon={<DeleteOutlined/>} 
                        disabled={conversationHistory.length === 0}
                >
                        消去
                </Button>
            </div>
            <div>
                {conversationHistory.map((message, index) => (
                    <React.Fragment key={index}>
                        {renderContent(message, index)}
                        <Divider />
                    </React.Fragment>
                ))}
            </div>
        </div>
      );
} 
 
export default AnalyzeComments