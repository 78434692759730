export const PATHS = {
    login: '/login',
    talents: '/talents',
    talentCloud: '/talentCloud',
    dashboard: '/dashboard/:nameId?',
    keywordSearch: '/keywordSearch/:dataSource?/:name?/:date?/:keyword?',
    userSettings: '/userSettings',
    fileArea: '/fileArea',
    chatGPT: '/chatGPT'
};

export const categories = ["スキャンダル", "ドラマ", "SNSブログ", "映画", "音楽", "雑誌", "ラジオ",
    "CM広告", "舞台", "バラエティー", "評価", "その他"];

export const sentimentTypes = {
    appearance: "容姿",
    likability: "好感度",
    performance: "演技力",
    potential: "将来性",
    other: 'その他',
    unclear: '不明'
};

export const commentTypes = {
    positive: 'ポジティブ',
    negative: 'ネガティブ',
    other: 'その他'
};

export const twitterSentimentTypes = {
    positive: 'ポジティブ',
    negative: 'ネガティブ',
    others: 'その他'
};

export const GRAVITY_API_ROOT = process.env.REACT_APP_GRAVITY_API_ROOT;
