import React, { useState, useEffect } from 'react';
import Ellipsis from 'ant-design-pro/lib/Ellipsis';
import { UPDATE_YAHOO_NEWS_ARTICLE, UPDATE_YAHOO_NEWS_COMMENT, UPDATE_TWITTER_SEARCH_RESULT, UPDATE_YOUTUBE_COMMENT, UPDATE_YOUTUBE_VIDEO } from '../../mutations';
import {
    Tabs,
    Button,
    Card,
    Form,
    Typography,
    Table,
    Spin,
    Space,
    Select,
    Input,
    DatePicker,
    Row,
    Col,
    Empty,
    Popover,
    Checkbox,
    Radio,
    Popconfirm
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { InfoCircleOutlined } from '@ant-design/icons';
import { GET_TALENTS, GET_TWITTER_SEARCH_RESULTS, GET_YAHOO_NEWS_ARTICLES, GET_YAHOO_NEWS_COMMENTS, GET_YOUTUBE_COMMENTS, GET_YOUTUBE_VIDEOS } from '../../queries';
import locale from 'antd/es/date-picker/locale/ja_JP';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { categories, sentimentTypes, commentTypes, twitterSentimentTypes } from '../../constants';
import { useParams } from 'react-router-dom';
import * as moment from 'moment';
import SummarizeComments from '../OpenAI/summarizeComments';

const momentify = datetime => <Popover
    content={new Date(datetime).toLocaleDateString(
        'ja-JP',
        { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
    >
    { moment(datetime).locale('ja').format('YYYY年MM月DD日') }
</Popover>;

const KeywordSearch = () => {
    const savedFormParams = localStorage.keywordSearch && JSON.parse(localStorage.keywordSearch);
    const { dataSource: paramDataSource, name: paramName, date: paramDate, keyword: paramKeyword } = useParams();

    const defaultTalent = () => {
        if (paramName) {
            return paramName;
        } else if (savedFormParams) {
            return savedFormParams.selectedNameId;
        } else {
            return ''
        }
    };

    const defaultDates = () => {
        if (paramDate){
            return [moment(paramDate.split(',')[0]), moment(paramDate.split(',')[1])];
        } else if (savedFormParams?.date) {
            return [moment(savedFormParams.date[0]), moment(savedFormParams.date[1])];
        } else {
            return [moment().subtract(1, 'months'), moment()];
        }
    };

    const defaultKeyword = () => {
        if (paramKeyword) {
            return paramKeyword;
        } else if (savedFormParams && savedFormParams.keyword) {
            return savedFormParams.keyword;
        } else {
            return '';
        }
    };

    const defaultSearchQuery = () => {
        const talent = defaultTalent();
        if (keyword && keyword.length > 0) {
            return `${keyword} AND talent_id=${defaultTalent()} AND date_range="${defaultDates()}`
        } else if (talent && talent.length > 0) {
            return `talent_id=${talent} AND date_range="${defaultDates()}`
        } else {
            return ''
        }
    }

    const [rows, setRows] = useState([]);
    const [originalRows, setOriginalRows] = useState([]);
    const [selectedNameId, setSelectedNameId] = useState(defaultTalent())
    const [category, setCategory] = useState('')
    const [commentType, setCommentType] = useState('')
    const [searchAllTalents, setSearchAllTalents] = useState(false);
    const noRetweets = false
    const [searchScope, setSearchScope] = useState(''); // title~, comment~
    const [keyword, setKeyword] = useState(defaultKeyword());
    const [search, setSearch] = useState(defaultSearchQuery());
    const [dataSource, setDataSource] = useState(paramDataSource || 'YahooNewsArticle')

    const sentimentFilter = [
        { text: 'ポジティブ', value: 'positive' },
        { text: 'ネガティブ', value: 'negative' },
        { text: 'その他', value: 'other' },
    ];

    let queryType;
    if (dataSource === 'YahooNewsArticle') {
        queryType = GET_YAHOO_NEWS_ARTICLES;
    } else if (dataSource === 'YahooNewsComment') {
        queryType = GET_YAHOO_NEWS_COMMENTS;
    } else if (dataSource === 'TwitterSearchResult') {
        queryType = GET_TWITTER_SEARCH_RESULTS;
    } else if (dataSource === 'YoutubeVideo') {
        queryType = GET_YOUTUBE_VIDEOS;
    } else if (dataSource === 'YoutubeComment') {
        queryType = GET_YOUTUBE_COMMENTS;
    }
    const { data: names, loading: loadingNames } = useQuery(GET_TALENTS, {variables: { isVisible: true }});
    const [getKeywordData, { data, loading }] = useLazyQuery(queryType);
    const getCommentsForArticle = commentTitle => {
        setDataSource('YahooNewsComment');
        setSearch(`title="${commentTitle}"`);
    };
    const getCommentsForVideo = videoId => {
        setDataSource('YoutubeComment');
        setSearch(`video_id=${videoId}`);
    };

    const handleCompleted = (data) => {
        setRows(rows.map(row => {
            if (dataSource === 'YahooNewsComment') {
                if (data.updateYahooNewsComment.yahooNewsComment.id === row.id) {
                    row.labelFlag = true;
                    row.sentimentType = data.updateYahooNewsComment.yahooNewsComment.sentimentType;
                    row.commentType = data.updateYahooNewsComment.yahooNewsComment.commentType;
                    const labeledBy = data.updateYahooNewsComment.yahooNewsComment.labeledBy;
                    row.labeledBy = `${labeledBy.lastName} ${labeledBy.firstName}`;
                    return row;
                }
            } else if (dataSource === 'YahooNewsArticle') {
                if (data.updateYahooNewsArticle.yahooNewsArticle.id === row.id) {
                    row.labelFlag = true;
                    row.category = data.updateYahooNewsArticle.yahooNewsArticle.category;
                    const labeledBy = data.updateYahooNewsArticle.yahooNewsArticle.labeledBy;
                    row.labeledBy = `${labeledBy.lastName} ${labeledBy.firstName}`;
                    return row;
                }
            } else if (dataSource === 'TwitterSearchResult') {
                if (data.updateTwitterSearchResult.twitterSearchResult.id === row.id) {
                    row.labelFlag = true;
                    row.sentimentSign = data.updateTwitterSearchResult.twitterSearchResult.sentimentSign;
                    return row;
                }
            } else if (dataSource === 'YoutubeVideo') {
                if (data.updateYoutubeComment.youtubeComment.id === row.id) {
                    row.labelFlag = true;
                    row.sentiment = data.updateYoutubeVideo.youtubeVideo.sentiment;
                    return row;
                }
            } else if (dataSource === 'YoutubeComment') {
                if (data.updateYoutubeComment.youtubeComment.id === row.id) {
                    row.labelFlag = true;
                    row.sentiment = data.updateYoutubeComment.youtubeComment.sentiment;
                    return row;
                }
            }

            return row;
        }));
    };

    const onCheckboxChange = e => {
        setSearchAllTalents(!searchAllTalents);
    };

    const [labelComment] = useMutation(UPDATE_YAHOO_NEWS_COMMENT, {
        onCompleted: (data) => { handleCompleted(data); },
        onError: (error) => { console.log(error); }
    });

    const [labelArticle] = useMutation(UPDATE_YAHOO_NEWS_ARTICLE, {
        onCompleted: (data) => { handleCompleted(data); },
        onError: (error) => { console.log(error); }
    });

    const [labelTweet] = useMutation(UPDATE_TWITTER_SEARCH_RESULT, {
        onCompleted: (data) => { handleCompleted(data); },
        onError: (error) => { console.log(error); }
    });

    const [labelYoutubeComment] = useMutation(UPDATE_YOUTUBE_COMMENT, {
        onCompleted: (data) => { handleCompleted(data); },
        onError: (error) => { console.log(error); }
    });
    const [labelYoutubeVideo] = useMutation(UPDATE_YOUTUBE_VIDEO, {
        onCompleted: (data) => { handleCompleted(data); },
        onError: (error) => { console.log(error); }
    });

    const handleLabelChange = (rowContent, value, type) => {
        let input;

        if (dataSource === 'YahooNewsArticle') {
            input = { id: rowContent.id, newCategory: value };
            labelArticle({ variables: { input }});
        } else if (dataSource === 'YahooNewsComment') {
            input = { id: rowContent.id };
            if (type === 'commentType') {
                input.newSentimentType = rowContent.sentimentType;
                input.newCommentType = value;
            } else if (type === 'sentimentType'){
                input.newSentimentType = value;
                input.newCommentType = rowContent.commentType;
            }
            labelComment({ variables: { input }});
        } else if (dataSource === 'TwitterSearchResult') {
            input = { id: rowContent.id, sentimentSign: value };
            labelTweet({ variables: { input }});
        } else if (dataSource === 'YoutubeComment') {
            input = { id: rowContent.id, sentiment: value };
            labelYoutubeComment({ variables: { input }});
        } else if (dataSource === 'YoutubeVideo') {
            input = { id: rowContent.id, category: value };
            labelYoutubeVideo({ variables: { input }});
        }
    };

    const yahooNewsArticleColumns = [
        {
            title: <Typography.Text strong>日付</Typography.Text>,
            dataIndex: 'date',
            render: rowContent => momentify(rowContent),
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
            width: "10%"
        },
        {
            title: <Typography.Text strong>ニュース記事タイトル</Typography.Text>,
            dataIndex: 'title',
            width: "20%",
            render: rowContent => <Ellipsis tooltip length={100}>{rowContent}</Ellipsis>
        },
        {
            title: <Typography.Text strong>内容</Typography.Text>,
            dataIndex: 'content',
            render: rowContent => <Ellipsis tooltip length={100}>{rowContent}</Ellipsis>,
            width: "40%"
        },
        {
            title: <Typography.Text strong>コメント数</Typography.Text>,
            render: rowContent => <Button type="text" onClick={() => getCommentsForArticle(rowContent.title)}><u>{rowContent.comment}</u></Button>,
            sorter: (a, b) => a.comment - b.comment,
            width: "10%"
        },
        {
            title: <Typography.Text strong>カテゴリー</Typography.Text>,
            width: "10%",
            sorter: (a, b) => {
                return a.category.localeCompare(b.category, 'ja');
            },
            render: rowContent => <Select
                placeholder="選択してください"
                onChange={value => handleLabelChange(rowContent, value, 'commentType')}
                style={{ minWidth: '100px' }}
                defaultValue={rowContent.category || 'その他'}
            >
                {
                    categories.map(category => <Select.Option key={category} value={category}>{category}</Select.Option>)
                }
            </Select>
        },
        {
            title: <Typography.Text strong>ラベル編集者</Typography.Text>,
            width: "10%",
            dataIndex: 'labeledBy'
        }
    ]

    const yahooNewsCommentColumns = [
        {
            title: <Typography.Text strong>日付</Typography.Text>,
            dataIndex: 'date',
            render: rowContent => momentify(rowContent),
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
            width: "10%"
        },
        {
            title: <Typography.Text strong>ニュース記事タイトル</Typography.Text>,
            dataIndex: 'title',
            width: "20%",
            render: rowContent => <Ellipsis tooltip length={100}>{rowContent}</Ellipsis>
        },
        {
            title: <Typography.Text strong>内容</Typography.Text>,
            dataIndex: 'content',
            render: rowContent => <Ellipsis tooltip length={100}>{rowContent}</Ellipsis>,
            width: "30%"
        },
        {
            title: <Typography.Text strong>タイプ</Typography.Text>,
            render: rowContent => <Select
                placeholder="選択してください"
                onChange={value => handleLabelChange(rowContent, value, 'sentimentType')}
                defaultValue={sentimentTypes[rowContent.sentimentType]}
                style={{ minWidth: '100px' }}
            >
                {
                    Object.keys(sentimentTypes).map(
                        sentimentType =>
                            <Select.Option key={sentimentType} value={sentimentType}>
                                {sentimentTypes[sentimentType]}
                            </Select.Option>
                    )
                }
            </Select>,
            sorter: (a, b) => {
                if (!sentimentTypes[a.sentimentType]) return -1;
                if (!sentimentTypes[b.sentimentType]) return 1;
                return sentimentTypes[a.sentimentType].localeCompare(sentimentTypes[b.sentimentType], 'ja');
            },
            width: "10%"
        },
        {
            title: <Typography.Text strong>感情</Typography.Text>,
            render: rowContent => <Select
            placeholder="選択してください"
            onChange={value => handleLabelChange(rowContent, value, 'commentType')}
            defaultValue={commentTypes[rowContent.commentType]}
            style={{minWidth: '70px'}}
        >
                {
                    Object.keys(commentTypes).map(
                        commentType =>
                            <Select.Option key={commentType} value={commentType}>
                                {commentTypes[commentType]}
                            </Select.Option>
                    )
                }
        </Select>,
            filters: sentimentFilter,
            onFilter: (value, record) => record.commentType === value,
            sorter: (a, b) => {
                return commentTypes[a.commentType].localeCompare(commentTypes[b.commentType], 'ja')
            },
            width: "10%",
        },
        {
            title: <Typography.Text strong><span role="img" aria-label="upvotes">👍</span></Typography.Text>,
            width: "5%",
            sorter: (a, b) => a.scoreGood - b.scoreGood,
            dataIndex: 'scoreGood'
        }, {
            title: <Typography.Text strong><span role="img" aria-label="downvotes">👎</span></Typography.Text>,
            width: "5%",
            sorter: (a, b) => a.scoreBad - b.scoreBad,
            dataIndex: 'scoreBad'
        },
        {
            title: <Typography.Text strong>ラベル編集者</Typography.Text>,
            width: "10%",
            dataIndex: 'labeledBy'
        }
    ]

    const twitterSearchResultColumns = [
        {
            title: <Typography.Text strong>日付</Typography.Text>,
            dataIndex: 'date',
            render: rowContent => momentify(rowContent),
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
            width: "10%"
        },
        {
            title: <Typography.Text strong>フォロワー数</Typography.Text>,
            dataIndex: 'followersCount',
            sorter: (a, b) => a.followersCount - b.followersCount,
            width: "10%"
        }, 
        {
            title: <Typography.Text strong>内容</Typography.Text>,
            dataIndex: 'content',
            render: rowContent => <Ellipsis tooltip length={100}>{rowContent}</Ellipsis>,
            width: "40%"
        },
        {
            title: <Typography.Text strong>タイプ</Typography.Text>,
            render: rowContent => <Select
                placeholder="選択してください"
                onChange={value => handleLabelChange(rowContent, value, 'sentimentSign')}
                value={twitterSentimentTypes[rowContent.sentimentSign]}
            >
                {
                    Object.keys(twitterSentimentTypes).map(
                        sentimentSign =>
                            <Select.Option key={sentimentSign} value={sentimentSign}>
                                {twitterSentimentTypes[sentimentSign]}
                            </Select.Option>
                    )
                }
            </Select>,
            sorter: (a, b) => {
                if (!twitterSentimentTypes[a.sentimentSign]) return -1;
                if (!twitterSentimentTypes[b.sentimentSign]) return 1;
                return twitterSentimentTypes[a.sentimentSign].localeCompare(twitterSentimentTypes[b.sentimentSign], 'ja');
            },
            width: "25%"
        },
    ]

    const youtubeVideoColumns = [
        {
            title: <Typography.Text strong>日付</Typography.Text>,
            dataIndex: 'date',
            render: rowContent => momentify(rowContent),
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
            width: "10%"
        },
        {
            title: <Typography.Text strong>動画タイトル</Typography.Text>,
            dataIndex: 'title',
            width: "20%",
        }, {
            title: <Typography.Text strong>リンク</Typography.Text>,
            width: "10%",
            dataIndex: 'videoId',
            render: rowContent => <a href={`https://www.youtube.com/watch?v=${rowContent}`} target="_blank" rel="noopener noreferrer">{rowContent}</a>

        }, {
            title: <Typography.Text strong>内容</Typography.Text>,
            dataIndex: 'description',
            width: "30%",
            render: rowContent => <Ellipsis tooltip length={100}>{rowContent}</Ellipsis>
        }, {
            title: <Typography.Text strong>視聴数</Typography.Text>,
            width: "10%",
            sorter: (a, b) => a.viewCount - b.viewCount,
            render: rowContent => <Typography.Text>{rowContent.viewCount}</Typography.Text>,
        }, {
            title: <Typography.Text strong>コメント数</Typography.Text>,
            width: "10%",
            sorter: (a, b) => a.commentCount - b.commentCount,
            render: rowContent => <Button type="text" onClick={() => getCommentsForVideo(rowContent.videoId)}><u>{rowContent.commentCount}</u></Button>,
        }, {
            title: <Typography.Text strong>カテゴリー</Typography.Text>,
            width: "10%",
            sorter: (a, b) => a.category.localeCompare(b.category, 'ja'),
            render: rowContent => <Select
                placeholder="選択してください"
                onChange={value => handleLabelChange(rowContent, value, 'commentType')}
                style={{ minWidth: '100px' }}
                defaultValue={rowContent.category}
            >
                { categories.map(category => <Select.Option key={category} value={category}>{category}</Select.Option>) }
            </Select>
        },
        {
            title: <Typography.Text strong>ラベル編集者</Typography.Text>,
            width: "10%",
            dataIndex: 'labeledBy'
        }
    ]

    const youtubeCommentColumns = [
        {
            title: <Typography.Text strong>日付</Typography.Text>,
            dataIndex: 'date',
            render: rowContent => momentify(rowContent),
            sorter: (a, b) => new Date(a.date) - new Date(b.date),
            width: "10%"
        },
        {
            title: <Typography.Text strong>動画タイトル</Typography.Text>,
            dataIndex: 'videoTitle',
            width: "20%",
        }, {
            title: <Typography.Text strong>リンク</Typography.Text>,
            width: "10%",
            dataIndex: 'videoId',
            render: rowContent => <a href={`https://www.youtube.com/watch?v=${rowContent}`} target="_blank" rel="noopener noreferrer">{rowContent}</a>

        }, {
            title: <Typography.Text strong>コメント</Typography.Text>,
            dataIndex: 'comment',
            width: "30%",
        }, {
            title: <Typography.Text strong>感情</Typography.Text>,
            width: "10%",
            render: rowContent => (<Select
                placeholder="選択してください"
                onChange={value => handleLabelChange(rowContent, value, 'commentType')}
                style={{ minWidth: '100px' }}
                defaultValue={rowContent.sentiment || 'その他'}
            >
                {
                    ['positive', 'negative', 'other'].map(commentType =>
                        <Select.Option key={commentType} value={commentType}>{commentTypes[commentType] || 'その他'}</Select.Option>
                    )
                }
            </Select>
            ),
            filters: sentimentFilter,
            onFilter: (value, record) => record.sentiment === value,
            sorter: (a, b) => {
                // for youtube comments, the data column is "sentiment" whereas yahoo comment is "comment_type"
                return commentTypes[a.sentiment].localeCompare(commentTypes[b.sentiment], 'ja');
            },
        },{
            title: <Typography.Text strong><span role="img" aria-label="upvotes">👍</span></Typography.Text>,
            width: "10%",
            sorter: (a, b) => a.likeCount - b.likeCount,
            dataIndex: 'likeCount'
        }, {
            title: <Typography.Text strong>ラベル編集者</Typography.Text>,
            width: "10%",
            dataIndex: 'labeledBy'
        }
    ]

    const columns = dataSource === 'YahooNewsArticle' ? yahooNewsArticleColumns :
        dataSource === 'YahooNewsComment' ? yahooNewsCommentColumns :
        dataSource === 'TwitterSearchResult' ? twitterSearchResultColumns :
        dataSource === 'YoutubeVideo' ? youtubeVideoColumns :
        dataSource === 'YoutubeComment' ? youtubeCommentColumns : [];

    const [form] = Form.useForm();

    useEffect(() => {
        let selectedName;
        let newRows;
        if (data && names && selectedNameId) {
            selectedName = names.talents.edges.find(talent => talent?.node.id === selectedNameId)?.node.name;
        }
        if (data && data.yahooNewsArticles) {
            newRows = data.yahooNewsArticles.edges.map(article => ({
                key: article.node.id,
                date: article.node.day,
                talentName: selectedName,
                id: article.node.id,
                comment: article.node.comment,
                title: article.node.title,
                labeledBy: article.node.labeledBy && `${article.node.labeledBy.lastName} ${article.node.labeledBy.firstName}`,
                category: article.node.category,
                content: article.node.content || article.node.title
            }));
        } else if (data && data.yahooNewsComments) {
            newRows = data.yahooNewsComments.edges.map(comment => ({
                key: comment.node.id,
                id: comment.node.id,
                labeledBy: comment.node.labeledBy && `${comment.node.labeledBy.lastName} ${comment.node.labeledBy.firstName}`,
                date: comment.node.createdAt,
                talentName: selectedName,
                title: comment.node.title,
                commentType: comment.node.commentType,
                sentimentType: comment.node.sentimentType,
                category: comment.node.category,
                scoreGood: comment.node.scoreGood,
                scoreBad: comment.node.scoreBad,
                content: comment.node.comment
            }))
        } else if (data && data.twitterSearchResults) {
            newRows = data.twitterSearchResults.edges.map(tweet => ({
                key: tweet.node.id,
                id: tweet.node.id,
                date: tweet.node.createdDt,
                talentName: selectedName,
                content: tweet.node.hoverText,
                followersCount: tweet.node.followersCount,
                sentimentSign: tweet.node.sentimentSign,
            }))
        } else if (data && data.youtubeComments) {
            newRows = data.youtubeComments.edges.map(comment => ({
                key: comment.node.id,
                id: comment.node.id,
                date: comment.node.publishedAt,
                talentName: selectedName,
                labeledBy: comment.node.labeledBy && `${comment.node.labeledBy.lastName} ${comment.node.labeledBy.firstName}`,
                comment: comment.node.comment,
                likeCount: comment.node.likeCount,
                sentiment: comment.node.sentiment,
                videoTitle: comment.node.youtubeVideo?.title,
                videoId: comment.node.videoId
            }))
        } else if (data && data.youtubeVideos) {
            newRows = data.youtubeVideos.edges.map(video => ({
                key: video.node.id,
                id: video.node.id,
                date: video.node.publishedAt,
                talentName: selectedName,
                labeledBy: video.node.labeledBy && `${video.node.labeledBy.lastName} ${video.node.labeledBy.firstName}`,
                commentCount: video.node.youtubeComments?.length,
                category: video.node.category,
                description: video.node.description,
                sentiment: video.node.sentiment,
                title: video.node.title,
                videoId: video.node.videoId,
                viewCount: video.node.viewCount
            }))

            // Sort newRows based on viewCount in descending order
            newRows.sort((a, b) => b.viewCount - a.viewCount);
        }
        if (data) {
            setOriginalRows(newRows);
            setRows(newRows);
        }
    }, [data, names, selectedNameId])

    useEffect(() => {
        if (search !== '') {
            getKeywordData({variables: { search: search }});
        }
    }, [getKeywordData, search])

    useEffect(() => {
        setRows(originalRows.filter(row => {
            if (commentType && category) {
                return row.category === category && row.commentType === commentType
            }
            if (commentType) {
                return row.commentType === commentType
            }
            if (category) {
                return row.category === category
            }
            return true
        }));
    }, [originalRows, category, commentType])

    let subtitle;

    if (data && !loading) {
        switch (dataSource) {
            case 'TwitterSearchResult':
                subtitle = <>リツイート総数：<b>{data.twitterSearchResults.totalCount}</b></>
                break;
            case 'YahooNewsArticle':
                let commentsCount = 0;
                if (data && data.yahooNewsArticles && !data.yahooNewsArticles.edges) {
                    data.yahooNewsArticles.edges.map(article => article.node.comment).reduce((a, b) => (a + b));
                }
                subtitle = <>記事総数: <b>{data?.yahooNewsArticles?.totalCount || 0}</b> | コメント数: <b>{commentsCount}</b></>
                break;
            case 'YahooNewsComment':
                let positiveCommentsCount = 0, negativeCommentsCount = 0;

                data.yahooNewsComments.edges.forEach(comment => {
                    if (comment.node.commentType === 'positive') {
                        positiveCommentsCount += 1;
                    } else if (comment.node.commentType === 'negative') {
                        negativeCommentsCount += 1;
                    }
                })
                subtitle = <>コメント総数: <b>{data.yahooNewsComments.totalCount}</b> | ポジティブ数: <b>{positiveCommentsCount}</b> | ネガティブ数: <b>{negativeCommentsCount}</b></>
                break;
            default:
        }
    }

    const generateSearchKeyword = (scope, keyword) => {
        if (scope === '') {
            return keyword;
        } else {
            const keywords = keyword.split(' ');
            return keywords.map((keyword) => { return scope + keyword }).join(' AND ');
        }
    }

    const handleFormSubmit = ({ talentname, date, dataSource, keyword }) => {
        const cleanedKeyword = keyword?.replace(/\s+/g, ' ') || '';
        const searchKeyword = generateSearchKeyword(searchScope, cleanedKeyword);
        const searchKeywordWithNoRT = noRetweets ? 'NOT RT ' + searchKeyword : searchKeyword
        setSelectedNameId(talentname);
        setCategory('');
        setCommentType('');
        setKeyword(keyword)
        searchAllTalents
        ? setSearch(`${(keyword && `(${searchKeywordWithNoRT}) AND `) || ''}date_range="${date}"`)
        : setSearch(`${(keyword && `(${searchKeywordWithNoRT}) AND `) || ''}talent_id=${talentname} AND date_range="${date}"`);
        localStorage.setItem(
            'keywordSearch',
            JSON.stringify({
                selectedNameId: talentname,
                date,
                keyword
            })
        );
    };

    const handleRadioChange = (e) => {
        setSearchScope(e.target.value);
    };

    const helpContent = <>
    <ol>
        <p>「全て」を選択して以下の方法で検索してください。</p>
        <li>タイトルとコメント別々で検索ヒットさせる場合</li>
       <b>title~演技 AND comment~素晴らしい</b>　
        <li>タイトルまたはコメントどちらかで検索ヒットさせる場合</li>
        <b> title~演技 OR comment~素晴らしい</b> 
    </ol>
</>;

    return <>
        <Space direction='vertical' style={{ width: '100%' }}>
            <Card title={<Typography.Title level={3}>キーワード検索</Typography.Title>}>
                <Form form={form} layout='inline'
                    size='large'
                    initialValues={{
                        date: defaultDates(),
                        keyword: keyword,
                        talentname: defaultTalent()
                    }}
                    onFinish={handleFormSubmit}
                >
                    <Row gutter={[16,8]}>
                        <Col>
                            <Space direction="vertical">
                                <Form.Item name='keyword' label="キーワード">
                                    <Input allowClear style={{ width: 500 }} suffix={<SearchOutlined />} placeholder="検索" />
                                </Form.Item>
                                
                                {dataSource === 'YahooNewsComment' &&
                                    <div style={{ marginLeft: '5rem' }}>
                                        <Radio.Group onChange={handleRadioChange} value={searchScope}>
                                            <Radio value={''}>全て</Radio>
                                            <Radio value={'comment~'}>コメント内容</Radio>
                                            <Radio value={'title~'}>タイトル</Radio>
                                        </Radio.Group>
                                       
                                    <Button icon={<InfoCircleOutlined />} type='link'>
                                        <Popover title='キーワードの検索方法' trigger='click' content={helpContent}>
                                                キーワードの検索方法
                                        </Popover>
                                    </Button>
                                    </div>
                                }
                            </Space>
                        </Col>
                     
                        <Col>
                            <Form.Item required name='talentname' label="タレント名"　 style={{ marginRight: '0px' }}>
                                <Select
                                    showSearch
                                    placeholder="選択してください"
                                    style={{ width: 200 }}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    disabled={searchAllTalents}
                                >
                                    { !loadingNames && names.talents.edges.map(talent =>
                                    <Select.Option key={talent.node.name} value={talent.node.id}>{talent.node.name}</Select.Option>
                                                                              )
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item>
                                <Checkbox onChange={onCheckboxChange}>全てのタレント</Checkbox>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item required name='date' label="日付" rules={[{ required: true, message: '日付を入力してください' }]}>
                                <DatePicker.RangePicker locale={locale}/>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                             style={{ marginRight: '0px' }}>
                                <Button
                                    onClick={() => {
                                        form.setFieldsValue(
                                            {
                                                keyword: undefined,
                                                talentname: undefined,
                                                date: [moment().subtract(1, 'months'), moment()]
                                            }
                                        )
                                        localStorage.setItem(
                                            'keywordSearch',
                                            JSON.stringify({
                                                selectedNameId: undefined,
                                                date: [moment().subtract(1, 'months'), moment()],
                                                keyword: undefined
                                            })
                                        );
                                    }}
                                    type='secondary'
                                >全てリセット</Button>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item>
                                <Button htmlType="submit" type='primary'>表示</Button>
                            </Form.Item>
                        </Col>
                        <Col>
                            { loading && <Form.Item><Spin/></Form.Item> }
                        </Col>
                    </Row>
                </Form>
            </Card>
            <Card title={<Typography.Title level={3}>コメントに関する質問</Typography.Title>}>
            <SummarizeComments 
                comments={rows}
                talentName={names?.talents?.edges?.find((talent) => {
                    return talent.node.id === selectedNameId
                })?.node?.name}
            />
            </Card>
            <Tabs
                onChange={
                    (activeKey) => {
                        setSearchScope('')
                        setDataSource(activeKey);
                        const formValues = form.getFieldsValue();
                        setSelectedNameId(formValues.talentname);
                        setCategory('');
                        setCommentType('');
                        searchAllTalents
                        ? setSearch(`${(formValues.keyword && `${formValues.keyword} AND `) || ''}date_range="${formValues.date}"`)
                        : setSearch(`${(formValues.keyword && `${formValues.keyword} AND `) || ''}talent_id=${formValues.talentname} AND date_range="${formValues.date}"`);
                    }
                }
                defaultActiveKey={dataSource}
                activeKey={dataSource}
                style={{ background: 'white', padding: '20px', marginBottom: '-40px' }}
            >
                <Tabs.TabPane key='YahooNewsArticle' tab='YahooNewsカテゴリー'>{ loading && <Spin/> }</Tabs.TabPane>
                <Tabs.TabPane key='YahooNewsComment' tab='YahooNewsコメント'>{ loading && <Spin/> }</Tabs.TabPane>
                <Tabs.TabPane key='YoutubeVideo' tab='Youtube動画'>{ loading && <Spin/> }</Tabs.TabPane>
                <Tabs.TabPane key='YoutubeComment' tab='Youtubeコメント'>{ loading && <Spin/> }</Tabs.TabPane>
            </Tabs>

            { (!data || loading) &&
                <div className='dashboard-box' style={{ padding: 100, height: 600, backgroundColor: 'white'}}>
                    <Empty />
                </div>
            }

            { (data && !loading) &&
            <Table
                columns={columns}
                style={{ padding: '0px 24px 24px 24px', background: '#fff'}}
                dataSource={rows}
                title={() => <>
                    <Row  gutter={[16,0]} >
                            {
                                data && dataSource === 'YahooNewsComment' &&
                                    <Col span={12} >
                                        <Form.Item name='comment_type' label="コメントタイプ" >
                                            <Select
                                                onChange={value => setCommentType(value)}
                                                placeholder="選択してください"
                                                allowClear
                                            >
                                                {
                                                    ['positive', 'negative', 'other'].map(
                                                        type => <Select.Option key={type} value={type}>
                                                            {commentTypes[type]}
                                                        </Select.Option>
                                                    )
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                            }
                            {
                                data && (dataSource === 'YahooNewsComment' || dataSource === 'YahooNewsArticle') &&
                                    <Col span={12} >
                                        <Form.Item name='category' label="カテゴリー">
                                            <Select
                                                onChange={value => setCategory(value) }
                                                placeholder="選択してください"
                                                allowClear
                                            >
                                                {
                                                    categories.map(
                                                        category => <Select.Option key={category} value={category}>
                                                            {category}
                                                        </Select.Option>
                                                    )
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                            }
                    </Row>
                    <Row gutter={[16,0]}>
                        <Col style={{ textAlign: 'left', flex: 1 }}>
                            { subtitle }
                        </Col>
                        { dataSource === 'TwitterSearchResult' && keyword && keyword !== '' && 
                            <Col style={{ textAlign: 'right' }}>
                            { Object.keys(twitterSentimentTypes).map(k => {
                                return <Popconfirm
                                    title={`${twitterSentimentTypes[k]}にラベル変更しますか？`}
                                    onConfirm={() => {
                                        rows.forEach(row => {
                                            if (row.sentimentSign !== k) {
                                                const input = { id: row.id, sentimentSign: k };
                                                labelTweet({ variables: { input }});
                                            }
                                        });
                                    }}
                                    okText="はい"
                                    cancelText="いいえ"
                                >
                                <Button type='secondary' style={{ marginRight: '10px' }}>全て{twitterSentimentTypes[k]}にラベル変更</Button>
                                </Popconfirm>
                            })}
                            </Col> 
                        }

                    </Row>
                </>}
            />}
        </Space>
    </>
};

export default KeywordSearch;
